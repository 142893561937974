import React, { useEffect, useState } from "react";
import { FaPaperPlane, FaQuestion, FaTrash } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageUploader from "../../atoms/ImageUploader/ImageUploader";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import TrumpCard from "../../atoms/TrumpCard/TrumpCard";
import useAuth from "../../hooks/useAuth";
import useDeleteDoc from "../../hooks/useDeleteDoc";
import useDoc from "../../hooks/useDoc";
import "./Tier.scss";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default function Tier() {
  let { id } = useParams();
  let { data, update } = useDoc({ coll: "lebewesen", id });
  let [tier, setTier] = useState({});
  useEffect(() => {
    setTier(data);
  }, [data, setTier]);
  console.log(tier);
  let { del } = useDeleteDoc({ coll: "lebewesen" });
  let nav = useNavigate();
  useAuth();
  return (
    <Layout className="Tier">
      <div className="flex column">
        <div className="flex">
          <h1>{tier.name} bearbeiten</h1>
          <div className="flex1"></div>
          <div
            className="deleteQuestionButton"
            onClick={() =>
              confirmAlert({
                title: "Wirklich löschen?",
                message: "Möchten Sie das Tier wirklich Löschen?",
                buttons: [
                  {
                    label: "Ja",
                    onClick: () => {
                      del(data.id).then(() => nav("/tiere"));
                    },
                  },
                  {
                    label: "Nein",
                    onClick: () => {},
                  },
                ],
              })
            }
          >
            <FaTrash></FaTrash>
          </div>
        </div>
      </div>

      <TierForm data={tier} onChange={setTier}></TierForm>
      <Spacer h={12}></Spacer>
      <div className="button" onClick={() => update(tier)}>
        <FaPaperPlane></FaPaperPlane>&nbsp;Änderungen Speichern
      </div>
      <Spacer h={12}></Spacer>
      <Link to={"/tier/" + id + "/antworten"} className="actionLink">
        <FaQuestion></FaQuestion>&nbsp;Antworten bearbeiten
      </Link>
      <Spacer h={24}></Spacer>
    </Layout>
  );
}

export function TierForm({ data, onChange }) {
  let setField = (k, v) => {
    onChange({ ...data, [k]: v });
  };
  return (
    <div className="tierForm">
      <div className="left">
        <Input
          onChange={(v) => setField("name", v)}
          label="Name"
          value={data.name}
        ></Input>
        <Input
          onChange={(v) => setField("fachbegriff", v)}
          label={data.type === "Pflanze" ? "Familie" : "Fachbegriff"}
          value={data.fachbegriff}
        ></Input>
        <Input
          onChange={(v) => setField("gewicht", v)}
          label={data.type === "Pflanze" ? "Vorkommen" : "Gewicht"}
          value={data.gewicht}
        ></Input>
        <Input
          onChange={(v) => setField("alter", v)}
          label={data.type === "Pflanze" ? "Blüten" : "Alter"}
          value={data.alter}
        ></Input>
        <Input
          onChange={(v) => setField("brutzeit", v)}
          label={data.type === "Pflanze" ? "Frucht/Samen" : "Brut/Tragezeit"}
          value={data.brutzeit}
        ></Input>
        <Input
          onChange={(v) => setField("nachkommen", v)}
          label={data.type === "Pflanze" ? "Verwendung" : "Eier/Jungtiere"}
          value={data.nachkommen}
        ></Input>
        {data.type !== "Pflanze" && (
          <>
            <Input
              onChange={(v) => setField("bedrohung", v)}
              label="Bedrohung"
              value={data.bedrohung}
            ></Input>
            <Input
              onChange={(v) => setField("auftreten", v)}
              label="Häufigkeit"
              value={data.auftreten}
            ></Input>
          </>
        )}
        <TextArea
          onChange={(v) => setField("beschreibung", v)}
          label={"Beschreibung"}
          value={data.beschreibung}
        ></TextArea>
        <TextArea
          onChange={(v) => setField("beschreibung2", v)}
          label={"Ausführliche Beschreibung"}
          value={data.beschreibung2}
        ></TextArea>
        <select
          onChange={(e) => setField("etage", e.target.value)}
          className={"multiSelect"}
          value={data.etage}
        >
          <option value={null}>Nicht im Turm</option>
          <option value={"1"}>1. Wasser</option>
          <option value={"2"}>2. Boden</option>
          <option value={"3"}>3. Baum- und Krautschicht bis 5m</option>
          <option value={"4"}>4. Baum- und Strauchschicht bis 20m</option>
          <option value={"5"}>5. Baumschicht bis 40m</option>
          <option value={"6"}>6. Baumschicht bis 60m</option>
        </select>

        <Spacer h={12}></Spacer>
        <select
          onChange={(e) => setField("type", e.target.value)}
          className={"multiSelect"}
          value={data.type}
        >
          <option value={"Tier"}>Tier</option>
          <option value={"Pflanze"}>Pflanze</option>
        </select>

        <Spacer h={12}></Spacer>
        <ImageUploader
          value={data.image}
          onChange={(d) => setField("image", d)}
        ></ImageUploader>

        <Spacer h={12}></Spacer>
      </div>
      <div className="right">
        <TrumpCard data={data}></TrumpCard>
      </div>
    </div>
  );
}

function Input({ label, type = "text", value, onChange }) {
  return (
    <div className="input">
      <div className="label">{label}</div>
      <input
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      ></input>
    </div>
  );
}

function TextArea({ label, type = "text", value, onChange }) {
  return (
    <div className="input">
      <div className="label">{label}</div>
      <textarea
        onChange={(e) => onChange(e.target.value)}
        value={value}
      ></textarea>
    </div>
  );
}
