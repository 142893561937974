import React, { useState } from "react";
import Header from "../Header/Header";
import Nav from "../Nav/Nav";
import "./Layout.scss";

export default function Layout({ children, navHidden }) {
  let [nav, setNav] = useState(false);
  return (
    <div className="Layout">
      <Nav open={nav} onClose={() => setNav(false)}></Nav>
      <Header navHidden={navHidden} showNav={() => setNav(true)} />
      <div className="inner">
        <div className="wrap">{children}</div>
      </div>
    </div>
  );
}
