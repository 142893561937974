import React, { useState } from "react";
import { FaBan } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import useAuth from "../../hooks/useAuth";
import useDoc from "../../hooks/useDoc";
import useDocs from "../../hooks/useDocs";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import "./FrageAntworten.scss";

export default function FrageAntworten() {
  let { id } = useParams();
  let { data } = useDoc({ coll: "fragen", id });
  let { docs: tierListe } = useDocs({ coll: "lebewesen" });
  useAuth();
  return (
    <Layout className="FrageAntworten">
      <h1>Antworten für Frage</h1>
      <h2>{data.text}</h2>
      {data &&
        tierListe &&
        tierListe.map((t) => {
          return (
            <AnswerForAnimal
              options={data.options}
              question={data}
              animal={t}
            ></AnswerForAnimal>
          );
        })}
      <Link to={"/frage/" + id} className="actionLink">
        Zurück zur Frage
      </Link>
    </Layout>
  );
}

function AnswerForAnimal({ options, animal, question }) {
  let { update } = useUpdateDoc({ coll: "lebewesen" });
  let [selected, setSelected] = useState(animal.answers[question.id]);
  return (
    <div className="row">
      <Link to={"/tier/" + animal.id}>
        <div className="img">
          <img src={animal.image && animal.image.url} alt="" />
        </div>
      </Link>
      <div className="name">{animal.name}</div>
      <div className="flex1"></div>
      <div className="answers">
        {options.map((o) => {
          return (
            <div
              className={"ans " + (selected === o.id ? "selected " : " ")}
              onClick={() =>
                update(
                  animal.id,
                  { answers: { [question.id]: o.id } },
                  { mergeFields: true }
                ).then(() => setSelected(o.id))
              }
            >
              {o.text}
            </div>
          );
        })}
        <div
          className={"ans " + (!selected ? "selected " : " ")}
          onClick={() =>
            update(
              animal.id,
              { answers: { [question.id]: null } },
              { mergeFields: true }
            ).then(() => setSelected(null))
          }
        >
          <FaBan></FaBan>
        </div>
      </div>
    </div>
  );
}
