import { useEffect, useState } from "react";

import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export default function useDoc({ coll, id }) {
  const [data, setData] = useState({});
  useEffect(() => {
    const docRef = doc(db, coll, id);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        setData(docSnap.data());
      }
    });
  }, [coll, id]);
  const update = (newData, options) => {
    const docRef = doc(db, coll, id);
    return updateDoc(docRef, newData)
  }
  return { data, update };
}
