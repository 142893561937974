import React from "react";
import "./Header.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import images from "../../images";
import { Link } from "react-router-dom";
export default function Header({ showNav, navHidden }) {
  return (
    <div className="Header">
      <Link to="/" className="logo">
        <img alt="logo" src={images.logo}></img>
      </Link>
      {!navHidden && (
        <div className="inlineNav">
          <Link to="/" className="navItem">
            Home
          </Link>
          <Link to="/tiere" className="navItem">
            Lebewesen
          </Link>
          <Link to="/fragen" className="navItem">
            Fragen
          </Link>
        </div>
      )}
      {!navHidden && (
        <div className="menuButton" onClick={() => showNav()}>
          <GiHamburgerMenu></GiHamburgerMenu>
        </div>
      )}
    </div>
  );
}
