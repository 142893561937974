import React, { useEffect, useState } from "react";
import Layout from "../../atoms/Layout/Layout";
import "./Login.scss";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

export default function Login() {
  let [mail, setMail] = useState("admin@lemontree.xyz");
  let [pass, setPass] = useState("123123");
  const auth = getAuth();
  let nav = useNavigate();
  useEffect(() => {
    let unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        nav("/");
      } else {
      }
    });
    return () => unsub();
  }, [auth, nav]);
  return (
    <Layout className="Login">
      <div className="login">
        <h1>Bitte einloggen</h1>
        <input
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          type="text"
          className="loginInput"
          placeholder="E-Mail"
        ></input>
        <input
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          type="password"
          className="loginInput"
          placeholder="Passwort"
        ></input>
        <button onClick={() => signInWithEmailAndPassword(auth, mail, pass)}>
          Einloggen
        </button>
      </div>
    </Layout>
  );
}
