import React, { useEffect, useState } from "react";
import { FaPaperPlane, FaPlus, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import useAuth from "../../hooks/useAuth";
import useDeleteDoc from "../../hooks/useDeleteDoc";
import useDoc from "../../hooks/useDoc";
import "./Frage.scss";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const alphabet = "abcdefghijklm";
export default function Frage() {
  let { id } = useParams();
  let { data, update } = useDoc({ coll: "fragen", id });
  let [frage, setFrage] = useState({});
  useEffect(() => {
    setFrage(data);
  }, [data, setFrage]);
  console.log(frage);
  let { del } = useDeleteDoc({ coll: "fragen" });
  let nav = useNavigate();
  useAuth();
  return (
    <Layout className="Frage">
      <div className="flex column">
        <div className="flex">
          <h1>Frage bearbeiten</h1>
          <div className="flex1"></div>
          <div
            className="deleteQuestionButton"
            onClick={() =>
              confirmAlert({
                title: "Wirklich löschen?",
                message: "Möchten Sie die Frage wirklich Löschen?",
                buttons: [
                  {
                    label: "Ja",
                    onClick: () => {
                      del(data.id).then(() => nav("/fragen"));
                    },
                  },
                  {
                    label: "Nein",
                    onClick: () => {},
                  },
                ],
              })
            }
          >
            <FaTrash></FaTrash>
          </div>
        </div>
      </div>
      <FrageForm data={frage} onChange={setFrage}></FrageForm>
      <Spacer h={24}></Spacer>
      <div className="addAnswer" onClick={() => update(frage)}>
        <FaPaperPlane></FaPaperPlane>
        &nbsp;Änderungen speichern
      </div>
      <Spacer h={0}></Spacer>
      <Link className="actionLink" to={"/frage/" + id + "/antworten"}>
        Lösungen bearbeiten
      </Link>
    </Layout>
  );
}

export function FrageForm({ data, onChange }) {
  let setField = (k, v) => {
    onChange({ ...data, [k]: v });
  };
  return (
    <div className="flex column">
      <input
        value={data.text}
        type="text"
        className="questionInput"
        onChange={(e) => setField("text", e.target.value)}
      />
      <label className="answer">
        <input
          checked={data.adultOnly}
          onChange={() => setField("adultOnly", !data.adultOnly)}
          type="checkbox"
        ></input>
        Nur für Erwachsene
      </label>
      {data.options &&
        data.options.map((a, i) => {
          return (
            <div className="answer">
              {alphabet[i]}){" "}
              <input
                value={a.text}
                type="text"
                className="answerInput"
                onChange={(e) => {
                  onChange({
                    ...data,
                    options: [
                      ...data.options.map((d) => {
                        return d.id === a.id
                          ? { ...d, text: e.target.value }
                          : d;
                      }),
                    ],
                  });
                }}
              />
              <div
                className="deleteAnswerButton"
                onClick={() => {
                  confirmAlert({
                    title: "Wirklich löschen?",
                    message: "Möchten Sie die Antwort wirklich Löschen?",
                    buttons: [
                      {
                        label: "Ja",
                        onClick: () => {
                          onChange({
                            ...data,
                            options: [
                              ...data.options.filter((d) => {
                                return d.id !== a.id;
                              }),
                            ],
                          });
                        },
                      },
                      {
                        label: "Nein",
                        onClick: () => {},
                      },
                    ],
                  });
                }}
              >
                <FaTrash></FaTrash>
              </div>
            </div>
          );
        })}
      <div
        className="addAnswer"
        onClick={() =>
          onChange({
            ...data,
            options: [
              ...data.options,
              { id: Math.random().toString(), text: "" },
            ],
          })
        }
      >
        <FaPlus></FaPlus>
        &nbsp;Neue Antwortmöglichkeit hinzufügen
      </div>
    </div>
  );
}
