import React from "react";
import { FaPen, FaPlus, FaSearch, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import useAuth from "../../hooks/useAuth";
import useDeleteDoc from "../../hooks/useDeleteDoc";
import useDocs from "../../hooks/useDocs";
import "./Tiere.scss";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default function Tiere() {
  let { docs: tierListe, reload } = useDocs({ coll: "lebewesen" });
  useAuth();
  return (
    <Layout className="Tiere">
      <div className="flex">
        <h1>Lebewesen</h1>
        <div className="flex1"></div>
        <Link to="/neu/tier" className="addQuestion">
          <FaPlus></FaPlus> &nbsp;Neues Lebewesen hinzufügen
        </Link>
      </div>
      <div className="search">
        <FaSearch></FaSearch>
        <input type="text" className="searchInput" />
      </div>
      <div className="tierListe">
        {tierListe.map((a) => {
          return <Tier data={a} reload={reload}></Tier>;
        })}
        <Link to="/neu/tier" className="addQuestionTextLink">
          <FaPlus></FaPlus> &nbsp; Neues Lebewesen hinzufügen
        </Link>
      </div>
    </Layout>
  );
}

function Tier({ data, reload }) {
  let { del } = useDeleteDoc({ coll: "lebewesen" });
  return (
    <Link to={"/tier/" + data.id} className="tier">
      <div className="img">
        <img src={data.image && data.image.url} alt="Panda" />
      </div>
      <div className="name">
        {data.name}
        <br></br>
        <i>{data.fachbegriff}</i>
      </div>
      <div className="flex1"></div>
      <div
        className="questionButton trash"
        onClick={(e) => {
          e.preventDefault();

          confirmAlert({
            title: "Sind Sie sicher?",
            message: "Möchten Sie das Tier wirklich Löschen?",
            buttons: [
              {
                label: "Ja",
                onClick: () => {
                  del(data.id).then(() => reload());
                },
              },
              {
                label: "Nein",
                onClick: () => {},
              },
            ],
          });
          e.stopPropagation();
        }}
      >
        <FaTrash></FaTrash>
      </div>
      <div className="questionButton">
        <FaPen></FaPen>
      </div>
    </Link>
  );
}
