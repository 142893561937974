import React from "react";
import Layout from "../../atoms/Layout/Layout";
import useAuth from "../../hooks/useAuth";
import { useUser } from "../../zustand/useUser";
import "./Home.scss";

export default function Home() {
  let user = useUser();
  console.log(user);
  useAuth();
  return <Layout className="Home"></Layout>;
}
