import React from "react";
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import useAuth from "../../hooks/useAuth";
import useDeleteDoc from "../../hooks/useDeleteDoc";
import useDocs from "../../hooks/useDocs";

import "./Fragen.scss";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default function Fragen() {
  let { docs: fragenList, reload } = useDocs({ coll: "fragen" });
  let { del } = useDeleteDoc({ coll: "fragen" });
  useAuth();
  return (
    <Layout className="Fragen">
      <div className="flex">
        <h1>Fragen</h1>
        <div className="flex1"></div>
        <Link to="/neu/frage" className="addQuestion">
          <FaPlus></FaPlus> &nbsp;Neue Frage hinzufügen
        </Link>
      </div>
      <div className="fragenListe">
        {fragenList.map((f) => {
          return (
            <Link to={"/frage/" + f.id} className="frageItem">
              {f.text}
              <div className="flex1"></div>
              <div
                className="questionButton"
                onClick={(e) => {
                  e.preventDefault();
                  confirmAlert({
                    title: "Sind Sie sicher?",
                    message: "Möchten Sie die Frage wirklich Löschen?",
                    buttons: [
                      {
                        label: "Ja",
                        onClick: () => {
                          del(f.id).then(() => reload());
                        },
                      },
                      {
                        label: "Nein",
                        onClick: () => {},
                      },
                    ],
                  });

                  e.stopPropagation();
                }}
              >
                <FaTrash></FaTrash>
              </div>
              <div className="questionButton trash">
                <FaPen></FaPen>
              </div>
            </Link>
          );
        })}
        <Link to="/neu/frage" className="addQuestionTextLink">
          <FaPlus></FaPlus> &nbsp; Neue Frage hinzufügen
        </Link>
      </div>
    </Layout>
  );
}
