import React, { useState } from "react";
import { FaBan, FaExternalLinkAlt } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import useAuth from "../../hooks/useAuth";
import useDoc from "../../hooks/useDoc";
import useDocs from "../../hooks/useDocs";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import "./TierAntworten.scss";

export default function TierAntworten() {
  let { id } = useParams();
  let { data } = useDoc({ coll: "lebewesen", id });
  let { docs: fragenList } = useDocs({ coll: "fragen" });
  useAuth();
  return (
    <Layout className="TierAntworten">
      <h1>Antworten für {data.name}</h1>
      {data &&
        fragenList &&
        fragenList.map((f) => {
          return <FrageForAnimal question={f} animal={data}></FrageForAnimal>;
        })}
      <Link to={"/tier/" + id} className="actionLink">
        Zurück zum Tier
      </Link>
    </Layout>
  );
}

function FrageForAnimal({ question, animal }) {
  let [selected, setSelected] = useState(animal.answers[question.id]);
  let { update } = useUpdateDoc({ coll: "lebewesen" });
  return (
    <div className="row">
      <Link to={"/frage/" + question.id}>
        <FaExternalLinkAlt />
      </Link>
      <div className="questionText">{question.text}</div>
      <div className="flex1"></div>
      <div className="answers">
        {question.options.map((o) => {
          return (
            <div
              onClick={() =>
                update(
                  animal.id,
                  { answers: { [question.id]: o.id } },
                  { mergeFields: true }
                ).then(() => setSelected(o.id))
              }
              className={"ans " + (selected === o.id ? "selected " : " ")}
            >
              {o.text}
            </div>
          );
        })}
        <div
          onClick={() =>
            update(
              animal.id,
              { answers: { [question.id]: null } },
              { mergeFields: true }
            ).then(() => setSelected(null))
          }
          className={"ans " + (!selected ? "selected " : " ")}
        >
          <FaBan></FaBan>
        </div>
      </div>
    </div>
  );
}
