// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCZcMH5EKD_7JVVeYnLd7WwZda1e1GZdRI",
    authDomain: "allwetterzoo-cb392.firebaseapp.com",
    projectId: "allwetterzoo-cb392",
    storageBucket: "allwetterzoo-cb392.appspot.com",
    messagingSenderId: "831294191757",
    appId: "1:831294191757:web:46966a6dbc05856610cac4"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
export const db = getFirestore();