import { useCallback, useEffect, useState } from "react";

import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

export default function useDocs({ coll }) {
    const [docs, setDocs] = useState([]);

    const updateData = useCallback(() => {
        getDocs(collection(db, coll)).then((querySnapshot) => {
            let newDocs = []
            querySnapshot.forEach((doc) => {
                newDocs.push(doc.data());
            });
            setDocs(newDocs);
        });
    }, [coll])
    useEffect(() => {
        console.log("getting stuff")
        updateData();
    }, [coll, updateData]);

    return { docs, reload: updateData };
}