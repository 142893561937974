import React from 'react'
import './App.scss'
import './fonts.scss'

import { Route, Routes } from 'react-router';
import Home from "./screens/Home/Home";
import Fragen from "./screens/Fragen/Fragen";
import Frage from "./screens/Frage/Frage";
import Tiere from "./screens/Tiere/Tiere";
import Tier from "./screens/Tier/Tier";
import TierAntworten from './screens/TierAntworten/TierAntworten';
import FrageAntworten from './screens/FrageAntworten/FrageAntworten';
import NeuesTier from './screens/NeuesTier/NeuesTier';
import NeueFrage from './screens/NeueFrage/NeueFrage';
import Login from './screens/Login/Login';

function App() {
  return (
    <Routes>
      <Route path={"/login"} element={<Login />} ></Route>
      <Route path={"/"} element={<Home />} ></Route>
      <Route path={"/fragen"} element={<Fragen />} ></Route>
      <Route path={"/frage/:id"} element={<Frage />} ></Route>
      <Route path={"/frage/:id/antworten"} element={<FrageAntworten />} ></Route>
      <Route path={"/tiere"} element={<Tiere />} ></Route>
      <Route path={"/neu/tier"} element={<NeuesTier />} ></Route>
      <Route path={"/neu/frage"} element={<NeueFrage />} ></Route>
      <Route path={"/tier/:id"} element={<Tier />} ></Route>
      <Route path={"/tier/:id/antworten"} element={<TierAntworten />} ></Route>
    </Routes>
  )
}

export default App;