import { useNavigate } from "react-router-dom";
import {
  getAuth, onAuthStateChanged
} from "firebase/auth"
import { useEffect } from "react";
export default function useAuth() {
  const nav = useNavigate();
  const auth = getAuth()
  useEffect(() => {

    let unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
      } else {
        nav("/login");
      }
    });
    return unsub;
  }, [auth, nav]);
}
