import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import useCreateDoc from "../../hooks/useCreateDoc";
import { FrageForm } from "../Frage/Frage";
import "./NeueFrage.scss";

const blankFrage = {
  text: "",
  options: [],
};

export default function NeueFrage() {
  let [frage, setFrage] = useState(blankFrage);
  let { create } = useCreateDoc({ coll: "fragen" });
  let nav = useNavigate();
  return (
    <Layout className="NeueFrage">
      <h1>Neue Frage erstellen</h1>
      <FrageForm data={frage} onChange={setFrage}></FrageForm>
      <Spacer h={24}></Spacer>
      <div
        className="button"
        onClick={() =>
          create(frage).then((id) => nav("/frage/" + id + "/antworten"))
        }
      >
        Speichern
      </div>
    </Layout>
  );
}
