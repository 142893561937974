import React from "react";
import "./Spacer.scss";

export default function Spacer({ w = 0, h = 0 }) {
  return (
    <div
      className="Spacer"
      style={{ width: w, height: h, minWidth: w, minHeight: h }}
    ></div>
  );
}
