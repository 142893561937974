import React from "react";
import { FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Spacer from "../Spacer/Spacer";
import "./Nav.scss";
import { getAuth } from "firebase/auth";

export default function Nav({ open, onClose }) {
  const nav = useNavigate();
  const auth = getAuth();
  if (!open) {
    return null;
  }
  return (
    <div className="Nav">
      <div className="navHeader">
        <div className="logo">
          <img alt="logo" src="./logo.png"></img>
        </div>
      </div>
      <div className="navInner">
        <div className="navWrap">
          <NavItem path="/">Startseite</NavItem>
          <NavItem path="/fragen">Fragen</NavItem>
          <NavItem path="/tiere">Lebewesen</NavItem>
          <div
            className="navItem"
            onClick={() => {
              auth.signOut().then(() => {
                nav("/login");
              });
            }}
          >
            Logout
          </div>
          <div className="navFooter">
            <div className="impressum">Impressum {"&"} Datenschutz</div>
            <Spacer h={8} />
            <div className="credits">© 2021 Allwetterzoo Münster</div>
          </div>
        </div>
      </div>
      <div className="closeNav" onClick={() => onClose()}>
        <FaTimes></FaTimes>
      </div>
    </div>
  );
}

function NavItem({ children, path }) {
  return (
    <Link to={path} className="navItem">
      {children}
    </Link>
  );
}
