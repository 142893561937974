import React, { useState } from "react";
import { FaPaperPlane } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import useCreateDoc from "../../hooks/useCreateDoc";
import { TierForm } from "../Tier/Tier";
import "./NeuesTier.scss";

const blankAnimal = {
  image: "smol",
  answers: {},
};

export default function NeuesTier() {
  let [tier, setTier] = useState(blankAnimal);
  let { create } = useCreateDoc({ coll: "lebewesen" });
  let nav = useNavigate();
  return (
    <Layout className="NeuesTier">
      <h1>Lebewesen hinzufügen</h1>
      <TierForm data={tier} onChange={setTier}></TierForm>
      <div
        className="button"
        onClick={() =>
          create(tier).then((id) => nav("/tier/" + id + "/antworten"))
        }
      >
        <FaPaperPlane></FaPaperPlane>&nbsp;Änderungen Speichern
      </div>
    </Layout>
  );
}
